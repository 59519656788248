<template>
<div>
    <div   style="padding-top:0px">
        <a-layout>
            <a-layout-content>
                <a-row class="s-layout" style="overflow-x:scroll">
                    <a-col :span="24" style="min-width:1100px;">
                        <div class="s-k-font1">
                            키워드 분석
                        </div>
                        <div class="s-k-bord1" >
                            <div style="display:flex;margin-bottom:20px;margin-top:10px">

                                <a-input style="margin-right:5px;width:200px;height:40px" v-model="storename" type="text" placeholder="스토어명을 입력해주세요"> </a-input>

                                <a-button class="s-k-btn2 s-k-font3" size="large" @click="onConfirm()">엔진 구동</a-button>
                                <div style="float:right;display:flex;right:0;position:absolute">

                                    <a-button class="s-k-font2 s-k-btn1" plain size="large" style="width:250px" @click="dialogVisible2=true"><i class="fal fa-info-circle">&nbsp;</i>기준 데이타 업로드 양식 추출 방법 보기</a-button>

                                 
                                    <upload-excel-component class="s-k-btn1" :on-success="handleSuccess" :before-upload="beforeUpload" />


                                   
                                    <a-button class="s-k-font2 s-k-btn1" plain size="large" @click="onExportExcel()">결과 다운로드</a-button>

                                    <a-button class="s-k-font2 s-k-btn1" plain size="large" @click="onLoadPastHistory()">과거 구동 데이타 불러오기</a-button>
                                </div>

                            </div>
                            <a-table :columns="columns" :data-source="tableData" :pagination="false" :loading="loading" :scroll="{ x: 1400, y: getHeight }">

                            </a-table>
                        </div>

                    </a-col>

                </a-row>
            </a-layout-content>
        </a-layout>
    </div>
    <a-modal v-model="dialogVisible" title="구동이력" :width="874">
        <template slot="footer">
            <a-button key="back" @click="dialogVisible=false">
                닫기
            </a-button>
        </template>
        <div>
            <a-table :columns="columnsHistory" :data-source="tableDataHistory" :pagination="false" :scroll="{ y: 400 }">

                <template slot="operation" slot-scope="text,record">
                    <a-button type="primary" @click="handleSelect(record.timestamp)">선택</a-button>

                    <a-button type="danger" style="margin-left:5px" @click="handleDelete(record.timestamp)">삭제</a-button>
                </template>
            </a-table>

        </div>
    </a-modal>
     <a-modal v-model="dialogVisible2" title="네이버 키워드 엑셀 추출 방법" :width="974">
        <template slot="footer">
            <a-button key="back" @click="dialogVisible2=false">
                닫기
            </a-button>
        </template>
        <div>
          <h6>(1) 네이버 광고 사이트 화면내 키워드 도구 버튼을 클릭해서 아래 화면으로 이동한다</h6>
          <h6>(2) 원하는 키워드를 입력 후 조회 한다 </h6>
          <h6>(3) 원하는 키워드 10개 에 대해서 엑셀 다운로드를 한다 </h6>
          <a href='https://searchad.naver.com/my-screen' target="_blank" > https://searchad.naver.com/my-screen </a>
          
           <img style="width:100%" src="@/assets/images/pages/sample.jpg"/>

        </div>

    </a-modal>
    <nav-footer></nav-footer>
</div>
</template>

<script>
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

String.prototype.trims = function() {
  return this.replace(/^\s+|\s+$/g, "");
};

import SubMenu2 from "../../layouts/nav/SubMenu2";
import NavFooter from "../../layouts/nav/NavFooter";
import firebase from "firebase";
import {
  getCurrentTimeNew,
  getCurrentTime2,
  getHex,
  replaceAll,
  showError,
  showSuccess
} from "../components/fnc.js";
import UploadExcelComponent from "../components/UploadExcel/index.vue";
import XLSX from "xlsx";
import FileSaver from "file-saver";

export default {
  data() {
    return {
      seq: 1,
      dialogVisible: false,
      dialogVisible2: false,
      storename: "",
      loading: false,
      loaded: false,
      tableData: [],
      talbeDataHistory: [],

      columnsHistory: [
        {
          title: "연번",
          dataIndex: "index",
          key: "index",
          width: 70
        },
        {
          title: "등록일",
          dataIndex: "date",
          key: "date",
          width: 170
        },
        {
          title: "상점명",
          dataIndex: "storename",
          key: "storename",
          width: 170
        },
        {
          title: "키워드",
          dataIndex: "keyword",
          key: "keyword"
        },
        {
          title: "선택",
          width: 200,
          dataIndex: "operation",
          scopedSlots: {
            customRender: "operation"
          }
        }
      ],

      columns: [
        {
          title: "연번",
          dataIndex: "index",
          key: "index",
          width: 70
        },
        {
          title: "상태",
          dataIndex: "status",
          width: 70,
          key: "status"
        },
        {
          title: "쇼핑연동",
          dataIndex: "relation",
          width: 100,
          key: "relation"
        },
        {
          title: "키워드",
          dataIndex: "keyword",
          width: 150,
          key: "keyword"
        },
        {
          title: "상점명",
          dataIndex: "storename",
          width: 150,
          key: "storename"
        },
        {
          title: "순위",
          dataIndex: "rank",
          width: 200,
          key: "rank"
        },
        {
          dataIndex: "needqty",
          width: 100,
          key: "needqty",
          title: () => {
            return (
              <div style="text-align:center">
                <span>
                  1 page진입 <br /> 필요 진행수{" "}
                </span>{" "}
              </div>
            );
          }
        },
        // {
        //   dataIndex: "price",
        //   width: 100,
        //   key: "price",
        //   title: () => {
        //     return (
        //       <div style="text-align:center">
        //         <span>
        //           1 명당 <br /> 진행비용{" "}
        //         </span>{" "}
        //       </div>
        //     );
        //   }
        // },
        // {
        //   dataIndex: "totalprice",
        //   width: 150,
        //   key: "totalprice",
        //   title: () => {
        //     return (
        //       <div style="text-align:center">
        //         <span>
        //           총 <br /> 진행비용{" "}
        //         </span>{" "}
        //       </div>
        //     );
        //   }
        // },
        {
          dataIndex: "totalday",
          width: 100,
          key: "totalday",
          title: () => {
            return (
              <div style="text-align:center">
                <span>
                  총 <br /> 진행일수{" "}
                </span>{" "}
              </div>
            );
          }
        },
        {
          dataIndex: "mspc",
          width: 100,
          key: "mspc",
          title: () => {
            return (
              <div style="text-align:center">
                <span>
                  월간검색수 <br /> (PC){" "}
                </span>{" "}
              </div>
            );
          }
        },
        {
          dataIndex: "msmobile",
          width: 100,
          key: "msmobile",
          title: () => {
            return (
              <div style="text-align:center">
                <span>
                  월간검색수 <br /> (모바일){" "}
                </span>{" "}
              </div>
            );
          }
        },
        {
          dataIndex: "totalproduct",
          width: 100,
          key: "totalproduct",
          title: "총상품수"
        },
        {
          dataIndex: "msperprod",
          width: 100,
          key: "msperprod",
          title: () => {
            return (
              <div style="text-align:center">
                <span>
                  월간검색수/ <br /> 총상품수{" "}
                </span>{" "}
              </div>
            );
          }
        },
        {
          dataIndex: "msperprice1",
          width: 100,
          key: "msperprice1",
          title: () => {
            return (
              <div style="text-align:center">
                <span>
                  1~5 위 <br /> 평균가격{" "}
                </span>{" "}
              </div>
            );
          }
        },
        {
          dataIndex: "msperprice2",
          width: 100,
          key: "msperprice2",
          title: () => {
            return (
              <div style="text-align:center">
                <span>
                  6~10 위 <br /> 평균가격{" "}
                </span>{" "}
              </div>
            );
          }
        },
        {
          dataIndex: "msperreview1",
          width: 100,
          key: "msperreview1",
          title: () => {
            return (
              <div style="text-align:center">
                <span>
                  1~5 위 <br /> 평균리뷰수{" "}
                </span>{" "}
              </div>
            );
          }
        },
        {
          dataIndex: "msperreview2",
          width: 100,
          key: "msperreview2",
          title: () => {
            return (
              <div style="text-align:center">
                <span>
                  6~10 위 <br /> 평균리뷰수{" "}
                </span>{" "}
              </div>
            );
          }
        },
        {
          dataIndex: "reg1",
          width: 100,
          key: "reg1",
          title: () => {
            return (
              <div style="text-align:center">
                <span>
                  1 위 <br /> 등록일{" "}
                </span>{" "}
              </div>
            );
          }
        },
        {
          dataIndex: "reg2",
          width: 100,
          key: "reg2",
          title: () => {
            return (
              <div style="text-align:center">
                <span>
                  2 위 <br /> 등록일{" "}
                </span>{" "}
              </div>
            );
          }
        },
        {
          dataIndex: "reg3",
          width: 100,
          key: "reg3",
          title: () => {
            return (
              <div style="text-align:center">
                <span>
                  3 위 <br /> 등록일{" "}
                </span>{" "}
              </div>
            );
          }
        },
        {
          dataIndex: "reg4",
          width: 100,
          key: "reg4",
          title: () => {
            return (
              <div style="text-align:center">
                <span>
                  4 위 <br /> 등록일{" "}
                </span>{" "}
              </div>
            );
          }
        },
        {
          dataIndex: "category",
          width: 300,
          key: "category",
          title: "카테고리"
        }
      ]
    };
  },
  components: {
    SubMenu2,
    NavFooter,
    UploadExcelComponent
  },
  computed: {
    getHeight() {
      return window.innerHeight - 300;
    },
    getName() {
      return this.$store.state.AppActiveUser.displayName;
    },
    getUid() {
      return this.$store.state.AppActiveUser.uid;
    },
    getEmail() {
      return this.$store.state.AppActiveUser.email;
    },
    getUserKey() {
      return this.$store.state.AppActiveUser.key;
    }
  },
  mounted() {
    fbq("track", "PageView");
  },
  methods: {
    onLoadData(key) {
      var db = firebase.firestore();
      var self = this;
      this.tableData = [];
      var _tableData = [];
      var _idx = 1;
      this.eventtimestamp = key;
      this.$vs.loading();

      self.no = 1;
      var _k = new Map();
      db
        .collection("analysis")
        .where("timestamp", "==", key)
        .where("uid", "==", this.getUid)
        .orderBy("msperprod", "desc")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            var _dd =
              Math.round(
                doc.data().msperprod / doc.data().totalproduct * 1000
              ) / 1000;

            _tableData.push({
              status: "완료",
              sysid: doc.id,
              index: _idx++,
              keyword: doc.data().keyword,
              storename: doc.data().storename,
              rank: doc.data().rank,
              desc: doc.data().desc,
              needqty: doc.data().needqty
                ? doc.data().needqty.toLocaleString()
                : "",
              price: doc.data().price ? doc.data().price.toLocaleString() : "",
              totalprice: doc.data().totalprice
                ? doc.data().totalprice.toLocaleString()
                : "",
              totalday: doc.data().totalday
                ? doc.data().totalday.toLocaleString()
                : "",
              mspc: doc.data().mspc ? doc.data().mspc.toLocaleString() : "",
              msmobile: doc.data().msmobile
                ? doc.data().msmobile.toLocaleString()
                : "",
              relation: doc.data().alloc == "T" ? "연동 O" : "연동 X",
              totalproduct: isNaN(_dd)
                ? ""
                : doc.data().totalproduct
                  ? doc.data().totalproduct.toLocaleString()
                  : "",
              msperprice1: doc.data().msperprice1
                ? doc.data().msperprice1.toLocaleString()
                : "",
              msperprice2: doc.data().msperprice2
                ? doc.data().msperprice2.toLocaleString()
                : "",
              msperreview1: doc.data().msperreview1
                ? doc.data().msperreview1.toLocaleString()
                : "",
              msperreview2: doc.data().msperreview2
                ? doc.data().msperreview2.toLocaleString()
                : "",
              reg1: doc.data().reg1,
              reg2: doc.data().reg2,
              reg3: doc.data().reg3,
              reg4: doc.data().reg4,
              seq: doc.data().seq ? doc.data().seq : 1,
              msperprod: isNaN(_dd) ? "" : _dd,
              mcpc: doc.data().mcpc,
              mcmobile: doc.data().mcmobile,
              mcrpc: doc.data().mcrpc,
              mcrmobile: doc.data().mcrmobile,
              vs: doc.data().vs,
              mdisplay: doc.data().mdisplay,
              category: doc.data().category,
              rank: doc.data().rank
            });
          });

          var _t = _tableData.sort(function(a, b) {
            // desc
            return a.msperprod > b.msperprod
              ? -1
              : a.msperprod < b.msperprod ? 1 : 0;
          });

          self.tableData = _t;
          self.$vs.loading.close();
        })
        .catch(function(error) {
          console.log("Error getting documents: ", error);
        });
    },
    handleSelect(_timestamp) {
      this.dialogVisible = false;
      this.onLoadData(_timestamp);
    },
    handleDelete(_timestamp) {
      var self = this;

      var db = firebase.firestore();
      var _dummy = [];
      db
        .collection("analysis")
        .where("timestamp", "==", _timestamp)
        .where("uid", "==", this.getUid)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            // _dummy.push(doc.id);
            db
              .collection("analysis")
              .doc(doc.id)
              .delete();
          });

          self.talbeDataHistory = self.talbeDataHistory.filter(item => {
            if (item.timestamp == row.timestamp) {
              //skip
            } else {
              return item;
            }
          });
          self.dialogVisible = false;
        });
    },
    onLoadPastHistory() {
      var db = firebase.firestore();
      var self = this;
      this.tableDataHistory = [];
      this.$vs.loading();

      var _k = new Map();
      var _idx = 1;
      db
        .collection("analysis")
        .where("uid", "==", this.getUid)
        .orderBy("eventtimestamp", "desc")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            var _d = {
              date: getCurrentTime2(
                new Date(doc.data().eventtimestamp.seconds * 1000)
              ),
              email: doc.data().email,
              timestamp: doc.data().timestamp,
              keyword: doc.data().keyword,
              storename: doc.data().storename
            };
            _k.set(_d.date, _d);
          });

          _k.forEach(item => {
            var _t = item;
            _t["index"] = _idx;
            self.tableDataHistory.push(_t);
            _idx++;
          });
          self.$vs.loading.close();
          self.dialogVisible = true;
        })
        .catch(function(error) {
          console.log("Error getting documents: ", error);
        });
    },
    onConfirm() {
      //DO NOT CHECK LICENSE
      var self = this;
      if (!this.storename) {
        showError({
          notify: this.$vs.notify,
          msg: "스토어명을 입력해주세요"
        });
      } else if (this.tableData.length == 0) {
        showError({
          notify: this.$vs.notify,
          msg: "기준데이타가 업로드 되지 않았습니다"
        });
      } else {
        //gogo

        this.$confirm({
          title: "확인",
          content:
            "예상 소요 시간이 3분이 소요될 예정입니다. 진행하시겠습니까?",
          okText: "네",
          cancelText: "취소",
          onOk() {
            self.onRun();
          },
          onCancel() {}
        });
      }
    },
    onRun() {
      var self = this;
      this.$vs.loading();

      this.$http
        .post(
          "https://us-central1-storelink-fnc3-305ec.cloudfunctions.net/startCrawl",
          {
            timestamp: self.eventtimestamp,
            mallName: self.storename
          }
        )
        .then(function(x) {
          self.$vs.loading.close();
          self.onLoadData(self.eventtimestamp);
        });

      this.onLoadDataSnapshot();

      var interval = 5000; //  = 2s
      var increment = 0;
    },
    onLoadDataSnapshot() {
      var self = this;
      if (this.eventtimestamp) {
        var db = firebase.firestore();

        db
          .collection("analysis")
          .where("timestamp", "==", this.eventtimestamp)
          .where("uid", "==", this.getUid)
          .onSnapshot(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
              if (doc.data()) {
                self.tableData.filter(item => {
                  if (item.sysid == doc.id) {
                    if (doc.data().totalproduct) {
                      item.status = "완료";

                      item.needqty = doc.data().needqty
                        ? parseInt(doc.data().needqty).toLocaleString()
                        : "";

                      item.price = doc.data().price
                        ? parseInt(doc.data().price).toLocaleString()
                        : "";

                      item.totalprice = doc.data().totalprice
                        ? parseInt(doc.data().totalprice).toLocaleString()
                        : "";

                      item.totalday = doc.data().totalday
                        ? parseInt(doc.data().totalday).toLocaleString()
                        : "";

                      item.mspc = doc.data().mspc
                        ? parseInt(doc.data().mspc).toLocaleString()
                        : "";

                      item.msmobile = doc.data().msmobile
                        ? parseInt(doc.data().msmobile).toLocaleString()
                        : "";

                      item.totalproduct = doc.data().totalproduct
                        ? parseInt(doc.data().totalproduct).toLocaleString()
                        : "";

                      item.msperprice1 = doc.data().msperprice1;
                      item.msperprice2 = doc.data().msperprice2;
                      item.msperreview1 = doc.data().msperreview1;
                      item.msperreview2 = doc.data().msperreview2;
                      item.reg1 = doc.data().reg1;
                      item.reg2 = doc.data().reg2;
                      item.reg3 = doc.data().reg3;
                      item.reg4 = doc.data().reg4;
                      item.msperprod =
                        Math.round(
                          doc.data().msperprod / doc.data().totalproduct * 1000
                        ) / 1000;
                      item.mcpc = doc.data().mcpc;
                      item.mcmobile = doc.data().mcmobile;
                      item.mcrpc = doc.data().mcrpc;
                      item.mcrmobile = doc.data().mcrmobile;
                      item.vs = doc.data().vs;
                      item.mdisplay = doc.data().mdisplay;
                      item.category = doc.data().category;
                      item.storename = doc.data().storename;
                      item.rank = doc.data().rank;
                    } else {
                      item.status = "구동중";
                    }
                  }
                  return item;
                });
              }
            });
          });
      }
    },
    handleSuccess({ results, header }) {
      var self = this;
      var timestamp = new Date();
      var timestamp2 = new Date().getTime();
      this.eventtimestamp = timestamp2;
      this.tableData = [];

      self.seq = 0;

      var _idx = 1;
      results.forEach(item => {
        if (_idx < 10) {
          if (item["연관키워드"]) {
            var _t = {
              keyword: item["연관키워드"],
              mspc: replaceAll(
                replaceAll(item["월간검색수(PC)"], "<", ""),
                " ",
                ""
              ),
              msmobile: replaceAll(
                replaceAll(item["월간검색수(모바일)"], "<", ""),
                " ",
                ""
              ),
              mcpc: item["월평균클릭수(PC)"],
              mcmobile: item["월평균클릭수(모바일)"],
              mcrpc: item["월평균클릭률(PC)"],
              mcrmobile: item["월평균클릭률(모바일)"],
              vs: item["경쟁정도"],
              mdisplay: item["월평균노출광고수"]
            };
            self.onAddNewRowByExcel(timestamp, _t, timestamp, timestamp2);
            _idx++;
          }
        }
      });
      this.loading = false;
      // this.onLoadHistory();
    },
    onAddNewRowByExcel(timestamp, item, _t1, _t2) {
      var db = firebase.firestore();
      var self = this;
      this.seq++;
      db
        .collection("analysis")
        .add({
          keyword: item.keyword ? item.keyword : "",
          storename: self.storename,
          mspc: item.mspc ? replaceAll(item.mspc, ",", "") : "",
          msmobile: item.msmobile ? replaceAll(item.msmobile, ",", "") : "",
          mcpc: item.mcpc ? replaceAll(item.mcpc, ",", "") : "",
          mcmobile: item.mcmobile ? replaceAll(item.mcmobile, ",", "") : "",
          mcrpc: item.mcrpc ? replaceAll(item.mcrpc, ",", "") : "",
          mcrmobile: item.mcrmobile ? replaceAll(item.mcrmobile, ",", "") : "",
          vs: item.vs ? item.vs : "",
          mdisplay: item.mdisplay ? item.mdisplay : "",
          eventtimestamp: _t1,
          timestamp: _t2,
          seq: this.seq,
          uid: this.getUid,
          email: this.getEmail,
          status: "",
          rank: "",
          desc: "",
          needqty: "",
          price: "",
          totalprice: "",
          totalday: "",
          totalproduct: "",
          msperprice1: "",
          msperprice2: "",
          msperreview1: "",
          msperreview2: "",
          reg1: "",
          reg2: "",
          reg3: "",
          reg4: "",
          msperprod: "",
          vs: "",
          mdisplay: "",
          category: ""
        })
        .then(function(mRef) {
          self.tableData.push({
            sysid: mRef.id,
            storename: self.storename,
            status: "",
            no: self.no++,
            keyword: item.keyword,
            rank: "",
            desc: "",
            needqty: "",
            price: "",
            totalprice: "",
            totalday: "",
            mspc: item.mspc,
            msmobile: item.msmobile,
            totalproduct: "",
            msperprice1: "",
            msperprice2: "",
            msperreview1: "",
            msperreview2: "",
            reg1: "",
            reg2: "",
            reg3: "",
            reg4: "",
            seq: self.seq,
            msperprod: "",
            mcpc: item.mcpc,
            mcmobile: item.mcmobile,
            mcrpc: item.mcrpc,
            mcrmobile: item.mcrmobile,
            vs: item.vs,
            mdisplay: item.mdisplay,
            category: ""
          });
        })
        .catch(function(error) {
          console.log("err", error);
        });
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 10;

      if (isLt1M) {
        this.loading = true;
        this.loaded = true;
        this.no = 1;
        return true;
      }

      showError({
        notify: self.$vs.notify,
        msg: "화일 사이즈가 10M 이하인 화일만 허용합니다."
      });

      return false;
    },
    onExportExcel() {
      var date = new Date();
      var self = this;
      var e1 = [
        [
          "연번",
          "상태",
          "쇼핑연동",
          "키워드",
          "상점명",
          "순위",
          "1page진입 필요진행수",
          "총 진행일수",
          "월간검색수(PC)",
          "월간검색수(모바일)",
          "총상품수",
          "월간검색수/총상품수",
          "1~5위 평균가격",
          "6~10위 평균가격",
          "1~5위 평균리뷰수",
          "6~10위 평균리뷰수",
          "1위 등록일",
          "2위 등록일",
          "3위 등록일",
          "4위 등록일",
          "카테고리"
        ]
      ];

      var wb = XLSX.utils.book_new();

      this.tableData.forEach(item => {
        e1.push([
          item.index,
          item.status,
          item.relation,
          item.keyword,
          item.storename,
          item.rank,
          item.needqty,
          item.totalday,
          item.mspc,
          item.msmobile,
          item.totalproduct,
          item.msperprod,
          item.msperprice1,
          item.msperprice2,
          item.msperreview1,
          item.msperreview2,
          item.reg1,
          item.reg2,
          item.reg3,
          item.reg4,
          item.category
        ]);
      });

      var ws1 = XLSX.utils.aoa_to_sheet(e1);

      // var ws2 = XLSX.utils.aoa_to_sheet(ws_data);

      XLSX.utils.book_append_sheet(wb, ws1, "데이타");

      // XLSX.utils.book_append_sheet(wb, ws2, 't2');

      // var wb = XLSX.utils.table_to_book(document.querySelector("#single"));

      /* get binary string as output */
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array"
      });

      try {
        FileSaver.saveAs(
          new Blob([wbout], {
            type: "application/octet-stream"
          }),
          "스토어링크-키워드분석-." + date.getTime() + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    }
  }
};
</script>

<style>
.s-k-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.74px;
  color: #000000;
}

.s-k-bord1 {
  margin-top: 16px;
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
}

.s-k-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #606060;
}

.s-k-font3 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  color: #ffffff;
}

.s-k-btn1 {
  margin-right: 10px;
}

button.s-k-btn2,
button.s-k-btn2:hover,
button.s-k-btn2:focus {
  color: white;
  background-color: #0264fb;
}
</style>
